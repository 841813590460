.title-layout {
  @apply flex flex-row items-center space-x-1
}

.title {
  @apply font-semibold text-base text-white
}

.social-icon {
  @apply text-gray-700 bg-white rounded-full p-0.5 mr-0.5
}

.description {
  @apply text-gray-500 text-sm font-normal whitespace-nowrap
}
